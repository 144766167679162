exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-faq-jsx": () => import("./../../../src/pages/page-faq.jsx" /* webpackChunkName: "component---src-pages-page-faq-jsx" */),
  "component---src-pages-page-general-type-jsx": () => import("./../../../src/pages/page-general-type.jsx" /* webpackChunkName: "component---src-pages-page-general-type-jsx" */),
  "component---src-pages-page-release-notes-jsx": () => import("./../../../src/pages/page-release-notes.jsx" /* webpackChunkName: "component---src-pages-page-release-notes-jsx" */),
  "component---src-pages-page-roadmap-jsx": () => import("./../../../src/pages/page-roadmap.jsx" /* webpackChunkName: "component---src-pages-page-roadmap-jsx" */),
  "component---src-pages-page-whats-new-jsx": () => import("./../../../src/pages/page-whats-new.jsx" /* webpackChunkName: "component---src-pages-page-whats-new-jsx" */),
  "component---src-pages-post-release-notes-jsx": () => import("./../../../src/pages/post-release-notes.jsx" /* webpackChunkName: "component---src-pages-post-release-notes-jsx" */),
  "component---src-pages-post-roadmap-jsx": () => import("./../../../src/pages/post-roadmap.jsx" /* webpackChunkName: "component---src-pages-post-roadmap-jsx" */),
  "component---src-pages-post-whats-new-jsx": () => import("./../../../src/pages/post-whats-new.jsx" /* webpackChunkName: "component---src-pages-post-whats-new-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-template-items-listing-js": () => import("./../../../src/template/ItemsListing.js" /* webpackChunkName: "component---src-template-items-listing-js" */)
}

