import React from "react"

const QuestionMarkIcon = () => {
  return (
    <svg
      className="w-6 h-6 float-left mr-2"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM9.29706 5.62281C10.1684 4.86433 11.2641 4.5 12.5441 4.5C13.8491 4.5 14.9381 4.87 15.7484 5.66174C16.5667 6.4475 16.9546 7.50391 16.9546 8.77022C16.9546 9.58054 16.7619 10.3459 16.3784 11.0589L16.3774 11.0609C15.9913 11.7711 15.2862 12.5584 14.3083 13.4208L14.3 13.4281L14.2914 13.4351C13.7496 13.8719 13.4213 14.4721 13.32 15.2777C13.2508 15.8275 12.7994 16.3356 12.165 16.3356C11.5511 16.3356 11.0044 15.8235 11.0696 15.1554C11.1228 14.6102 11.2326 14.1263 11.411 13.7158C11.6625 13.137 12.1747 12.5118 12.8859 11.8434C13.5856 11.1792 13.9982 10.7443 14.164 10.5172L14.1655 10.5152C14.3378 10.2816 14.4676 10.0296 14.5562 9.75748L14.5583 9.75122C14.6499 9.48203 14.7003 9.16392 14.7003 8.79043C14.7003 8.04413 14.4944 7.5225 14.1377 7.16L14.1326 7.15483C13.7853 6.7915 13.2796 6.58154 12.5441 6.58154C11.7969 6.58154 11.2427 6.78506 10.8326 7.1517C10.5426 7.41081 10.3538 7.73517 10.2656 8.14198C10.1567 8.64468 9.72781 9.14892 9.09127 9.14892C8.48791 9.14892 7.90099 8.63696 8.01409 7.93532C8.1636 7.00777 8.59335 6.22993 9.29706 5.62281ZM11.2235 18.29C11.493 18.0081 11.8503 17.8929 12.2279 17.8929C12.5994 17.8929 12.9481 18.0084 13.2142 18.282C13.4715 18.5327 13.6028 18.8534 13.6028 19.2116C13.6028 19.5713 13.4656 19.89 13.2064 20.1385C12.9371 20.3968 12.5905 20.5 12.2279 20.5C11.8624 20.5 11.5106 20.399 11.2389 20.1385L11.2307 20.1306L11.2229 20.1224C10.983 19.8705 10.8636 19.5568 10.8636 19.2116C10.8636 18.8641 10.9808 18.5449 11.2235 18.29Z"
        fill="var(--body-text)"
      />
    </svg>
  )
}

export default QuestionMarkIcon
